<template>
  <div>
    <client-only>
      <VDropdown placement="bottom">
        <WebccButton icon="site/share" :size="size" class="flex items-center" @click.stop=";[(open = !open), $emit('open')]">
          <span>{{ $t(`${trPrefix}button`) }}</span>
        </WebccButton>
        <template #popper>
          <div class="flex flex-col gap-4 rounded bg-bgr p-4 shadow-lg">
            <WebccLink anchor absolute :to="`https://www.facebook.com/sharer/sharer.php?u=${target}`" target="_blank" class="rounded-full">
              <WebccIcon class="h-10 w-10 text-[#3b5998]" name="social/facebook-share" />
            </WebccLink>
            <WebccLink anchor absolute :to="`https://x.com/intent/tweet?text=${target}`" target="_blank" class="rounded-full">
              <WebccIcon class="h-10 w-10 text-[#000000]" name="social/x-logo-share" />
            </WebccLink>
            <WebccLink
              anchor
              absolute
              :to="`mailto:?body=${target}`"
              target="_blank"
              class="rounded-full"
              @click.stop="useTracking().handlers?.detail.recommendByMail()"
            >
              <WebccIcon class="h-10 w-10" name="social/mail-share" filled />
            </WebccLink>
            <WebccLink anchor absolute :to="whatsAppLink" target="_blank" class="rounded-full">
              <WebccIcon class="h-10 w-10" name="social/whatsapp" filled />
            </WebccLink>
          </div>
        </template>
      </VDropdown>
    </client-only>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.SocialShare.'

const { t } = useI18n()

withDefaults(
  defineProps<{
    size?: Size
  }>(),
  {
    size: 'md',
  },
)

defineEmits(['open'])

const target: Ref<string> = ref('')
const open: Ref<boolean> = ref(false)
const partnerid = usePartner().id

const whatsAppLink = computed(() => `https://api.whatsapp.com/send/?text=${t(`${trPrefix}whatsApp`)}${target.value}`)
onMounted(() => {
  target.value = window.location.href.split('?')[0] + (partnerid ? `?partnerid=${partnerid}` : '')
})

onBeforeMount(() => {
  useToggler().register('socialshare')
})
</script>
