<template>
  <div v-if="show && visible" class="flex flex-col gap-12">
    <SearchObjectTeaser v-for="(entry, index) in apiResponse" :key="index" :type="type" :block="blocks[index]" :destination="destinationName" :data="entry" />
  </div>
</template>

<script setup lang="ts">
const MIN_HITS_TO_DISPLAY = 10
const MIN_RESULTS_TO_DISPLAY = 3
const PLACE_ONLY_BLOCKS = ['sea', 'pool']

const TYPE_BLOCKS: Record<ObjectTeaserType, ObjectTeaserBlock[]> = {
  destination: ['reviews', 'sustainable', 'wlan', 'parking', 'aircondition', 'sea', 'pool'],
  pool: ['reviews', 'sustainable', 'wlan', 'parking', 'aircondition'],
  sea: ['reviews', 'sustainable', 'wlan', 'parking', 'aircondition'],
  pets: ['reviews', 'sustainable', 'wlan', 'parking', 'hiking'],
  skiing: ['reviews', 'sustainable', 'wlan', 'fireplace', 'cheapcheap'],
  familyfriendly: ['reviews', 'sustainable', 'wlan', 'parking', 'aircondition'],
}

const TYPE_FILTERS: Record<ObjectTeaserType, Params> = {
  destination: {},
  pool: { pool: true },
  sea: { sea: 500 },
  pets: { pets: true },
  skiing: { skiing: true },
  familyfriendly: { familyfriendly: true },
}

const BLOCK_FILTERS: Record<ObjectTeaserBlock, Params> = {
  reviews: { sorting: '-averagerating', stars: 3, pagesize: 3 },
  sustainable: { sustainable: true, sorting: '-lastbookday', stars: 3, pagesize: 3 },
  wlan: { wlan: true, sorting: '-lastbookday', stars: 3, pagesize: 3 },
  parking: { parking: true, sorting: '-lastbookday', stars: 3, pagesize: 3 },
  aircondition: { aircondition: true, sorting: '-lastbookday', stars: 3, pagesize: 3 },
  hiking: { hiking: true, sorting: '-lastbookday', stars: 3, pagesize: 3 },
  fireplace: { fireplace: true, sorting: '-lastbookday', stars: 3, pagesize: 3 },
  cheapcheap: { cheapcheap: true, sorting: '-lastbookday', stars: 3, pagesize: 3 },
  sea: { sea: 500, sorting: '-lastbookday', stars: 3, pagesize: 3 },
  pool: { pool: true, sorting: '-lastbookday', stars: 3, pagesize: 3 },
}

const props = withDefaults(
  defineProps<{
    show?: boolean
    destination: Destination
  }>(),
  {
    show: true,
  },
)

const confData = useConfdata()
const search = useSearch()
const searchApi = useSearchApi()

const apiResponse = ref<Result[]>([])

const type = computed(() => (props.destination.filters?.[0].key || 'destination') as ObjectTeaserType)
const destinationName = computed(() => getDestinationName(props.destination))
const blocks = computed(() => TYPE_BLOCKS[type.value])
const visible = computed(() => apiResponse.value.filter((entry) => entry.totalHits >= MIN_HITS_TO_DISPLAY).length >= MIN_RESULTS_TO_DISPLAY)

onMounted(async () => {
  const { countryCode, regionCode, placeCode } = props.destination
  const season = useSeason(search.filters.checkin)
  const typeFilter = TYPE_FILTERS[type.value]
  const blocksValue = placeCode ? blocks.value : blocks.value.filter((block) => !PLACE_ONLY_BLOCKS.includes(block))

  const requests = blocksValue.map((block) => ({
    country: countryCode,
    region: regionCode,
    place: placeCode,
    ...typeFilter,
    ...BLOCK_FILTERS[block],
    currency: confData.currency.value,
    salesoffice: confData.salesoffice.value?.code,
    season,
  }))

  apiResponse.value = await searchApi.searchMulti(requests, confData.language).catch(() => [])
})
</script>
