import { defineNuxtPlugin } from '#app/nuxt'
import { LazyWebccBreadcrumb, LazyWebccButton, LazyWebccCancellationStepper, LazyWebccCaptcha, LazyWebccCompanyLogo, LazyWebccDateRangePlusMinusDays, LazyWebccDateRangeSearchFlex, LazyWebccDateRangeSearchSimple, LazyWebccDaterangeSearch, LazyWebccExpandable, LazyWebccFreeCancellationInfo, LazyWebccHeading, LazyWebccIcon, LazyWebccLink, LazyWebccLoader, LazyWebccLoaderOverlay, LazyWebccMarkdown, LazyWebccModal, LazyWebccPagination, LazyWebccScroll, LazyWebccSearch, LazyWebccTripPicker, LazyWebccCheckbox, LazyWebccDateRangepicker, LazyWebccDatepicker, LazyWebccForm, LazyWebccFormfield, LazyWebccInput, LazyWebccRadio, LazyWebccSelect, LazyWebccSwitch, LazyBlocks, LazyBlocksBreadcrumbs, LazyBlocksCatalogs, LazyBlocksColumns, LazyBlocksHeading, LazyBlocksHero, LazyBlocksLSODetail, LazyBlocksLSOList, LazyBlocksLSOMedia, LazyBlocksLandingContainer, LazyBlocksLandingH1, LazyBlocksLandingHero, LazyBlocksLandingIFrame, LazyBlocksLandingImageCarouselWithText, LazyBlocksLandingMarkdown, LazyBlocksLandingRecommendations, LazyBlocksLandingTeasers, LazyBlocksLandingTextCarousel, LazyBlocksLandingTextWithImage, LazyBlocksMedia, LazyBlocksMenu, LazyBlocksRichtext, LazyBlocksSearchBar, LazyBlocksStartHeaderTeasers, LazyBlocksStartInspirations, LazyBlocksStartOwnerTeaser, LazyBlocksStartRecommendations, LazyBlocksStartSEOLinks, LazyBlocksStartSeoTeaser, LazyBlocksStartTopDestinations, LazyBlocksStartUSPBar, LazyBlocksStartUSPCards, LazyBlocksStartUSPColumns, LazyBlocksTeaserGrid, LazyBlocksTeaserList, LazyBlocksTextColumns, LazyBlocksUSPBarBlock, LazyBlocksUSPCardsBlock, LazyBlocksUSPColumnsBlock, LazyBlocksUSPCorona, LazyErrorContent, LazyErrorFull, LazyPageBookmarks, LazyPageCatalogues, LazyPageContent, LazyPageDetails, LazyPageEmails, LazyPageMyrent, LazyPageNewsletter, LazyPageReviews, LazyPageSearch, LazyPageSearchRedesigned, LazyPageStart, LazyPageUnknown, LazyLiveness, LazyReadiness, LazyStartup } from '#components'
const lazyGlobalComponents = [
  ["WebccBreadcrumb", LazyWebccBreadcrumb],
["WebccButton", LazyWebccButton],
["WebccCancellationStepper", LazyWebccCancellationStepper],
["WebccCaptcha", LazyWebccCaptcha],
["WebccCompanyLogo", LazyWebccCompanyLogo],
["WebccDateRangePlusMinusDays", LazyWebccDateRangePlusMinusDays],
["WebccDateRangeSearchFlex", LazyWebccDateRangeSearchFlex],
["WebccDateRangeSearchSimple", LazyWebccDateRangeSearchSimple],
["WebccDaterangeSearch", LazyWebccDaterangeSearch],
["WebccExpandable", LazyWebccExpandable],
["WebccFreeCancellationInfo", LazyWebccFreeCancellationInfo],
["WebccHeading", LazyWebccHeading],
["WebccIcon", LazyWebccIcon],
["WebccLink", LazyWebccLink],
["WebccLoader", LazyWebccLoader],
["WebccLoaderOverlay", LazyWebccLoaderOverlay],
["WebccMarkdown", LazyWebccMarkdown],
["WebccModal", LazyWebccModal],
["WebccPagination", LazyWebccPagination],
["WebccScroll", LazyWebccScroll],
["WebccSearch", LazyWebccSearch],
["WebccTripPicker", LazyWebccTripPicker],
["WebccCheckbox", LazyWebccCheckbox],
["WebccDateRangepicker", LazyWebccDateRangepicker],
["WebccDatepicker", LazyWebccDatepicker],
["WebccForm", LazyWebccForm],
["WebccFormfield", LazyWebccFormfield],
["WebccInput", LazyWebccInput],
["WebccRadio", LazyWebccRadio],
["WebccSelect", LazyWebccSelect],
["WebccSwitch", LazyWebccSwitch],
["Blocks", LazyBlocks],
["BlocksBreadcrumbs", LazyBlocksBreadcrumbs],
["BlocksCatalogs", LazyBlocksCatalogs],
["BlocksColumns", LazyBlocksColumns],
["BlocksHeading", LazyBlocksHeading],
["BlocksHero", LazyBlocksHero],
["BlocksLSODetail", LazyBlocksLSODetail],
["BlocksLSOList", LazyBlocksLSOList],
["BlocksLSOMedia", LazyBlocksLSOMedia],
["BlocksLandingContainer", LazyBlocksLandingContainer],
["BlocksLandingH1", LazyBlocksLandingH1],
["BlocksLandingHero", LazyBlocksLandingHero],
["BlocksLandingIFrame", LazyBlocksLandingIFrame],
["BlocksLandingImageCarouselWithText", LazyBlocksLandingImageCarouselWithText],
["BlocksLandingMarkdown", LazyBlocksLandingMarkdown],
["BlocksLandingRecommendations", LazyBlocksLandingRecommendations],
["BlocksLandingTeasers", LazyBlocksLandingTeasers],
["BlocksLandingTextCarousel", LazyBlocksLandingTextCarousel],
["BlocksLandingTextWithImage", LazyBlocksLandingTextWithImage],
["BlocksMedia", LazyBlocksMedia],
["BlocksMenu", LazyBlocksMenu],
["BlocksRichtext", LazyBlocksRichtext],
["BlocksSearchBar", LazyBlocksSearchBar],
["BlocksStartHeaderTeasers", LazyBlocksStartHeaderTeasers],
["BlocksStartInspirations", LazyBlocksStartInspirations],
["BlocksStartOwnerTeaser", LazyBlocksStartOwnerTeaser],
["BlocksStartRecommendations", LazyBlocksStartRecommendations],
["BlocksStartSEOLinks", LazyBlocksStartSEOLinks],
["BlocksStartSeoTeaser", LazyBlocksStartSeoTeaser],
["BlocksStartTopDestinations", LazyBlocksStartTopDestinations],
["BlocksStartUSPBar", LazyBlocksStartUSPBar],
["BlocksStartUSPCards", LazyBlocksStartUSPCards],
["BlocksStartUSPColumns", LazyBlocksStartUSPColumns],
["BlocksTeaserGrid", LazyBlocksTeaserGrid],
["BlocksTeaserList", LazyBlocksTeaserList],
["BlocksTextColumns", LazyBlocksTextColumns],
["BlocksUSPBarBlock", LazyBlocksUSPBarBlock],
["BlocksUSPCardsBlock", LazyBlocksUSPCardsBlock],
["BlocksUSPColumnsBlock", LazyBlocksUSPColumnsBlock],
["BlocksUSPCorona", LazyBlocksUSPCorona],
["ErrorContent", LazyErrorContent],
["ErrorFull", LazyErrorFull],
["PageBookmarks", LazyPageBookmarks],
["PageCatalogues", LazyPageCatalogues],
["PageContent", LazyPageContent],
["PageDetails", LazyPageDetails],
["PageEmails", LazyPageEmails],
["PageMyrent", LazyPageMyrent],
["PageNewsletter", LazyPageNewsletter],
["PageReviews", LazyPageReviews],
["PageSearch", LazyPageSearch],
["PageSearchRedesigned", LazyPageSearchRedesigned],
["PageStart", LazyPageStart],
["PageUnknown", LazyPageUnknown],
["Liveness", LazyLiveness],
["Readiness", LazyReadiness],
["Startup", LazyStartup],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
