<template>
  <div v-if="visible" v-impression:detail:linkedObjectsTeaserViewed.100 class="flex flex-col gap-4 rounded-lg border border-bgr-200 bg-bgr p-6 shadow">
    <h2 class="text-2xl font-medium">{{ $t('www.components.views.details.LinkedObjects.title') }}</h2>
    <div class="flex flex-col gap-2 sm:grid sm:grid-cols-2 md:grid-cols-3 md:gap-10">
      <HitboxCard
        v-for="accom in objects"
        :key="accom.code"
        :breadcrumb-links="false"
        class="w-full"
        :accom="accom"
        :transformations="IMAGE_TRANSFORMATIONS"
        @to-detailpage="onPropertyClick"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const IMAGE_TRANSFORMATIONS = { xl: 'tr:n-srp_hitbox_l', lg: 'tr:n-srp_hitbox_l', md: 'tr:n-srp_hitbox_l' }

const props = defineProps<{
  code: string
}>()

const confData = useConfdata()
const searchApi = useSearchApi()
const tracking = useTracking()

const visible = computed(() => result.value && result.value.totalHits >= 3)
const objects = computed(() => result.value?.docs.slice(0, 3) ?? [])

const { data: result } = await useAsyncData(() => searchApi.getLinkedObjects(props.code, confData.language), {
  watch: [() => props.code, () => confData.language],
  server: false,
})

function onPropertyClick() {
  tracking.handlers?.detail.linkedObjectsTeaserClicked()
}
</script>
