<template>
  <WebccHeading v-if="titles.length" :level="1" color="txt" size="xl">
    <div class="flex flex-col items-start justify-center">
      <p v-if="titles[0]">{{ titles[0] }}</p>
      <p v-if="titles[1]" class="text-lg uppercase">{{ titles[1] }}</p>
      <p v-if="titles[2]" class="text-base">{{ titles[2] }}</p>
    </div>
  </WebccHeading>
</template>

<script setup lang="ts">
const params = useParams()
const confData = useConfdata()

const props = defineProps<{
  total: number
}>()

const titles = computed(() =>
  params.all.heading
    ? [params.all.heading] //
    : (confData.additionalBody as SearchPageBody).h1.map((line) => replacePlaceholders(line, { amount: props.total })),
)
</script>
