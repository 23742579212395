<template>
  <div class="bg-bgr-50">
    <section class="flex justify-center">
      <h1 class="container-fluid-lg flex h-20 items-center bg-thm pl-8 text-3xl text-bgr">{{ $t(`${trPrefix}title`) }}</h1>
    </section>

    <div class="container-fluid-lg relative mb-12 mt-4 flex flex-col text-sm lg:text-base">
      <FavBreadcrumbs />

      <div v-if="favorites.length > 0" class="mt-4 flex flex-col-reverse gap-4 bg-bgr-50 md:flex-row">
        <main class="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:w-3/4 md:grid-cols-1">
          <HitboxOrig v-for="item in filteredList" :key="item.code + '_orig'" class="hidden md:grid" :accom="item" />
          <HitboxCard v-for="item in filteredList" :key="item.code + '_card'" class="block md:hidden" :accom="item" />
        </main>
        <aside class="sticky inset-0 -ml-4 min-w-[100vw] bg-bgr pb-4 pt-4 md:ml-0 md:w-1/4 md:min-w-0 md:bg-bgr-50 md:pb-0">
          <div class="sticky top-4 mx-4 md:mx-0">
            <FavInfobox :show="paxDependentPrice && !hideInfobox" class="mb-4" @hide="hideInfobox = true" />
            <FavActions
              :accoms="favorites"
              class="my-0"
              :language="confData.language!"
              :partner-id="partner.id"
              :sales-office-id="confData.salesoffice?.code || ''"
              :privacy-policy="confData.links?.privacyPolicy?.href || ''"
              @clear-favorites="clearFavorites"
            />
          </div>
        </aside>
      </div>

      <div v-else-if="!pending" class="pt-8 text-lg">{{ $t(`${trPrefix}noList`) }}</div>
      <div v-else class="mt-48">
        <WebccLoaderOverlay absolute />
      </div>
    </div>
    <BlocksUSPCorona v-if="additionalBody.USPBar" boxed :icons="additionalBody.USPBar" />
    <USPCards v-if="additionalBody.USPCards" :why="additionalBody.USPCards.cards" />
    <USPWhy v-if="additionalBody.USPColumns" :why="[additionalBody.USPColumns.left, additionalBody.USPColumns.right]" />
    <FooterNewsletterSignup />
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.pages.favorites.'

defineProps<{}>()

const { t } = useI18n()
const confData = useConfdata()
const partner = usePartner()
const bookmarks = useBookmarks()
const tracking = useTracking()
const searchApi = useSearchApi()

const favorites = ref<ResultDocument[]>([])
const facets = ref({})
const sortorder = ref('age')
const hideInfobox = ref(false)
const pending = ref(true)

const additionalBody = computed(() => confData.additionalBody as FavoritespageBody)

onMounted(async () => {
  await nextTick(async () => {
    let ids = bookmarks.raw
    const url = new URL(window.location.href)
    if (url.searchParams.has('recommendationId')) {
      try {
        await bookmarks.loadPersonalCatalog(url.searchParams.get('recommendationId')!)
        ids = bookmarks.catalog!
      } catch (error) {
        console.error(error)
      }
    }
    if (ids.length === 0) {
      pending.value = false
      return
    }
    const params: { code: string[]; pagesize: number; locale: string } = {
      code: ids,
      pagesize: 0,
      locale: confData.locale,
    }
    params.pagesize = bookmarks.count
    try {
      const { data } = await searchApi.search({ code: ids, pagesize: ids.length, language: confData.language })

      let index = 1
      data.value?.docs?.forEach((doc) => {
        ;(doc as ResultDocument & { position: number }).position = index++
      })

      favorites.value = (data.value?.docs as ResultDocument[]) ?? []
      facets.value = data.value?.facets ?? {}

      tracking.handlers?.ecommerce.viewItemList(favorites.value)
      tracking.handlers?.eec.productImpression(favorites.value)
    } catch (error) {
      console.error(error)
    } finally {
      pending.value = false
    }
  })
})

// the check for occupancy-pricing is disabled because it's not clear if it's still needed and what information/text has to be shown
const paxDependentPrice = computed(() => {
  return false
})

const filteredList = computed(() => {
  return favorites.value.toSorted((elem1, elem2) => {
    switch (sortorder.value) {
      case 'ih':
        return 1
      case 'rating':
        return elem1.rating < elem2.rating ? 1 : -1
      case 'priceAsc':
        if (!elem1.trip?.price || !elem2.trip?.price) {
          return 1
        }
        return elem1.trip.price > elem2.trip.price ? 1 : -1
      case 'priceDesc':
        if (!elem1.trip?.price || !elem2.trip?.price) {
          return 1
        }
        return elem1.trip.price < elem2.trip.price ? 1 : -1
      case 'paxAsc':
        return elem1.pax > elem2.pax ? 1 : -1
      case 'paxDesc':
        return elem1.pax < elem2.pax ? 1 : -1
      default:
        return 1
    }
  })
})

function clearFavorites() {
  if (window.confirm(t(`${trPrefix}removeAll`))) {
    bookmarks.clear()
    favorites.value.forEach((accom) => {
      tracking.handlers?.ecommerce.removeFromWishlist([accom])
    })
    favorites.value = []
  }
}
</script>
