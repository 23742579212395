export default async function useContentAssets(params: { filter?: string }) {
  const { destination } = useConfdata()

  return await useNovaApi().getContentAssets({
    destination: getDestinationCode(destination),
    company: 'interhome', // TODO would be good to remove
    filter: params.filter || '',
    locale: useL10N().locale?.toLowerCase(),
  })
}
