<template>
  <div class="flex flex-col items-center" :class="[blur ? 'bg-black/10 backdrop-blur' : 'bg-gradient-to-b from-bgr-200 to-bgr-50']">
    <NuxtImg :src="`logos/interhome.svg`" class="h-14 my-8 opacity-50 grayscale" :class="{ invisible: $params.iframe || props.blur }" />
    <WebccLoader />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  blur?: boolean
}>()
</script>
