<template>
  <div class="my-4 flex flex-row flex-wrap gap-2 align-baseline">
    <div
      v-for="{ key, label } in prettyFilters"
      :key="key"
      class="mr-1 flex w-fit min-w-[4rem] items-center justify-between rounded-full bg-thm px-2 py-1 text-xs font-semibold uppercase text-thm-contrast last:mr-0 md:h-8"
    >
      <span>{{ label }}</span>
      <button @click.stop="removeFilter(key)">
        <WebccIcon name="site/x" class="ml-1 h-4 w-4" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import mapValues from 'lodash-es/mapValues'
import pickBy from 'lodash-es/pickBy'

type FilterPair = { key: string; label: string }

const trPrefix = 'www.components.views.search.filter.'
const trPrefixDistance = `${trPrefix}buckets.distances.`

const SKIP_PARAMS = { checkout: undefined, duration: undefined, sorting: undefined }

const BATCH_RESET: Record<keyof Params, Params> = {
  checkin: { checkin: undefined, checkout: undefined, duration: undefined },
  country: { country: undefined, region: undefined, place: undefined },
  region: { region: undefined, place: undefined },
}

const { t } = useI18n()
const conf = useConfdata()
const search = useSearch()

const prettyFilters = computed(() => {
  return pickBy(mapValues(search.filters, toPrettyFilter), Boolean) as Record<string, FilterPair>
})

function toPrettyFilter(value: unknown, key: string, filters: Params): FilterPair | undefined {
  if (!value || key in SKIP_PARAMS) return

  if (key in DESTINATION_PARAMS) return { key, label: labelFor(key, conf.destination?.[key as keyof typeof DESTINATION_PARAMS] ?? '') }
  if (key in DATE_PARAMS) return { key, label: labelFor('trip', formatDateRange(getTripDateRange(filters))) }
  if (key in NUMBER_PARAMS || key in STARS_PARAMS || key in TEXT_PARAMS) return { key, label: labelFor(key, value) }
  if (key in DISTANCE_PARAMS) return { key, label: labelFor(key, t(`${trPrefixDistance}${value}` as TranslationKey)) }
  if (key in PRICE_PARAMS) return { key, label: labelFor(key, formatCurrency(value as number, undefined, conf.currency)) }

  return { key, label: labelFor(key) }
}

function labelFor(key: string, value?: unknown) {
  return [t(`${trPrefix}${key}` as TranslationKey), value].filter(Boolean).join(': ')
}

function removeFilter(filter: string) {
  search.setFilter(BATCH_RESET[filter] ?? { [filter]: undefined })
}
</script>
