<template>
  <nav class="flex min-h-[2rem] w-full items-center justify-center gap-6 text-txt-strongest" role="navigation" aria-label="pagination">
    <WebccLink v-show="active > 1 && active <= total" :to="prev.url" @click.prevent="onSelect(prev.index)">
      <WebccIcon class="text-txt h-5 w-5" name="site/chevron-left" filled />
    </WebccLink>
    <ul class="flex flex-wrap items-center justify-center gap-3 text-xl">
      <li v-for="(item, i) in items" :key="i">
        <WebccLink
          v-if="item"
          :to="item.url"
          class="rounded border border-transparent px-2.5 py-1 transition-all hover:border-bgr-400"
          :class="{ 'border-txt-strongest bg-bgr hover:border-txt-strongest': item.index === active }"
          :aria-label="item.index.toString()"
          aria-current="page"
          :disabled="active === item.index"
          @click.prevent="onSelect(item.index)"
        >
          {{ item.index }}
        </WebccLink>
        <span v-else>&hellip;</span>
      </li>
    </ul>
    <WebccLink v-show="active < total" class="pagination-next" :to="next.url" @click.prevent="onSelect(next.index)">
      <WebccIcon class="text-txt h-6 w-6" name="site/chevron-right" filled />
    </WebccLink>
  </nav>
</template>

<script setup lang="ts">
import clamp from 'lodash-es/clamp'
import get from 'lodash-es/get'
import { withQuery } from 'ufo'

const props = withDefaults(
  defineProps<{
    active: number
    total: number
    uniqueKey?: string // TODO Remove this workaround when search uses Router to update history
    visible?: number
  }>(),
  { visible: 7, uniqueKey: '' },
)

const emit = defineEmits<{
  'select-page': [index: number]
}>()

const prev = computed(() => toPage(getIndexWithOffset(-1)))
const next = computed(() => toPage(getIndexWithOffset(+1)))
const items = computed(() => getPageNumbers(props.total, props.active, props.visible).map((item) => (isNaN(item) ? null : toPage(item))))

function getIndexWithOffset(offset: number): number {
  return clamp(props.active + offset, 1, props.total)
}

function toPage(index: number) {
  get(props, 'uniqueKey') // workaround to trigger computed update when search filters are changed TODO remove it
  return { index, url: withQuery(getUrlFullPath(useRequestURL()), { page: index }) }
}

function getPageNumbers(total: number, active: number, visible: number): number[] {
  if (props.total <= visible) {
    return range(1, total)
  }

  const even = visible % 2 === 0 ? 1 : 0
  const left = Math.floor(visible / 2)
  const right = total - left + 1 + even

  if (active > left && active < right) {
    const start = active - left + 2
    const end = active + left - 2 - even
    return [1, NaN, ...range(start, end), NaN, total]
  } else if (active === left) {
    const end = active + left - 1 - even
    return [...range(1, end), NaN, total]
  } else if (active === right) {
    const start = active - left + 1
    return [1, NaN, ...range(start, total)]
  }

  return [...range(1, left), NaN, ...range(right, total)]
}

function onSelect(index: number) {
  if (index < 1 || index > props.total) return

  emit('select-page', index)
}
</script>
