<template>
  <div ref="startRef" class="flex flex-col gap-4 justify-start">
    <template v-for="(doc, i) in docs" :key="JSON.stringify({ code: doc.code, trip: doc.trip })">
      <HitboxOrig v-track:impression="{ event: 'search:objectImpression' }" :accom="doc" :checkin="checkin" :checkout="checkout" @interaction="onInteraction" />

      <template v-if="!$params.iframe">
        <div v-if="i === 2 || (docs!.length < 3 && i === docs!.length - 1)" :key="i" class="hidden md:block">
          <SearchNewsletterSignup v-if="isSignUpEnabled" />
          <DetailsTrustPilot v-else :domain="confData.domain" :resource="confData.sites[0]" />
        </div>
      </template>
    </template>

    <WebccPagination
      v-if="pagesTotal > 1"
      class="mb-16"
      :active="pageActive"
      :total="pagesTotal"
      :unique-key="JSON.stringify(search.filters)"
      @select-page="loadPage"
    />
  </div>
</template>

<script setup lang="ts">
const confData = useConfdata()
const search = useSearch()
const tracking = useTracking()

const startRef = ref<HTMLDivElement>()

const { docs, total, filters } = storeToRefs(search)

const checkin = computed(() => tryParseDate(filters.value.checkin))
const checkout = computed(() => tryParseDate(filters.value.checkout))
const isSignUpEnabled = computed(() => confData.signupEnabled && confData.siteSettings?.includes('newsletter') && Math.random() > 0.5)
const pageActive = computed(() => tryParseInt(filters.value.page?.toString()) ?? 1)
const pagesTotal = computed(() => Math.ceil((total.value ?? 0) / 20))

async function loadPage(page: number) {
  tracking.handlers?.search.paging({ current: page, total: pagesTotal.value })
  await search.setFilter({ page })
  startRef.value?.scrollIntoView({ behavior: 'smooth' })
}

function onInteraction(event: {
  map?: boolean
  bookmark?: {
    accom: ResultDocument
    state: boolean
  }
}) {
  for (const key in event) {
    switch (key) {
      case 'map':
        tracking.handlers?.search.objectMap(event.map!)
        break
      case 'bookmark':
        tracking.handlers?.search.toggleBookmark({ accom: event.bookmark!.accom.code as string, state: event.bookmark!.state as boolean })
        break
    }
  }
}
</script>
