<template>
  <div
    :class="{ 'rounded-tr-lg': (!isOpen.value && state !== CheckPriceState.Available) || isOpen.value }"
    class="fixed bottom-0 z-1 w-full rounded-tl-lg bg-bgr shadow-[0_15px_15px_15px_rgba(0,0,0,0.3)] md:hidden"
  >
    <template v-if="!isOpen.value && state === CheckPriceState.Available">
      <div class="absolute -top-6 right-0 h-6 cursor-pointer rounded-tl-lg bg-bgr-400 px-2 py-1 text-xs text-white" @click.stop="toggle">
        {{ $t(`${trPrefix}changeDate`) }}
      </div>
    </template>
    <div v-if="vacancies && isOpen.value" class="relative h-0 overflow-hidden transition-height duration-700" :class="{ 'h-[25rem]': isOpen.value }">
      <button class="absolute right-2 top-2 cursor-pointer rounded-full bg-black/40 text-txt-contrast hover:bg-black/60" @click.stop="toggle">
        <WebccIcon name="site/x" class="h-6 w-6 stroke-2 p-0.5" />
      </button>

      <DetailsVacancy v-model="trip" class="top-6 w-full rounded p-2" :vacancies="vacancies" :pax-up-to="paxUpTo" />
    </div>

    <!-- Price -->
    <div :class="{ 'rounded-tl-none rounded-tr-none': isOpen.value }" class="rounded-tl-lg rounded-tr-lg px-2 py-2">
      <div class="float-right ml-2 flex grow-0 flex-col">
        <!-- booking button -->
        <WebccButton class="min-w-[10rem]" variant="cta" size="sm" @click.stop="onBookingClick(false)">
          <span>{{ $t(`${trPrefix}button.labels.${state}`) }}</span>
        </WebccButton>
        <!-- option button -->
        <WebccButton v-if="optionPossible" class="mt-2" variant="cta" size="sm" @click.stop="onBookingClick(true)">
          <span>{{ $t(`${trPrefix}button.labels.OP`) }}</span>
        </WebccButton>
      </div>
      <div class="mr-2 text-base font-semibold text-[#676767]">
        <p class="min-h-[1.5rem]">
          <span v-if="!isTripSelected">{{ $t(`${trPrefix}priceFrom`) }}</span> {{ formattedPrice }}
        </p>
        <p class="text-sm font-normal">
          <span v-if="!isTripSelected">{{ $t(`${trPrefix}eg`) }}</span> {{ formattedRange }}
        </p>
      </div>
      <div class="text-sm text-[#676767]">
        <span>{{ $t(`${trPrefix}upToPax`, trip.pax) }}</span> /
        <span v-tooltip="additionalCostsTooltip" class="cursor-pointer" @mouseenter.stop="onAdditionalCostsTooltipMouseEnter">
          <span>{{ $t(`${trPrefix}additionalCosts`) }}</span> <WebccIcon class="inline-flex relative top-1 h-4 w-4 text-thm" name="site/info" />
        </span>
      </div>
      <WebccFreeCancellationInfo
        v-if="trip.checkin"
        v-track:impression="{ event: 'search:freeCancellationImpression' }"
        :checkin-date="new Date(trip.checkin)"
        :free-cancellation-range="freeCancellationRange"
        class="mt-2"
        :locale="useL10N().locale"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.MobileBar.'

const { t } = useI18n()

interface Props {
  modelValue?: TripUpdate
  state: CheckPriceState
  price: AccomPrice
  vacancies?: Trips | null
  paxUpTo?: number
  optionPossible?: boolean
  accommodation: TranslatedAccommodation
  freeCancellationRange: number
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => ({}) as TripUpdate,
  price: () => ({}) as AccomPrice,
  vacancies: () => ({}) as Trips,
  paxUpTo: 1,
  optionPossible: false,
  freeCancellationRange: 0,
})

const emit = defineEmits<{
  'update:modelValue': [TripUpdate]
  book: [BookingOptions]
}>()

const toggleName = 'bottomAvailabilityCard'
useToggler().register(toggleName)

const currency = computed(() => useConfdata().currency)
const isOpen = computed(() => useToggler().toggles(toggleName))

const isTripSelected = computed(() => !!props.modelValue?.checkin)
const range = computed(() => (isTripSelected.value ? props.modelValue : props.price))
const formattedRange = computed(() => formatDateRange(getTripDateRange(range.value)))
const formattedPrice = computed(() => formatCurrency(props.price.price!, undefined, currency.value))

const trip = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
    if (isTripSelected.value) toggle()
  },
})

const additionalCostsTooltip = computed(() => {
  const dependentPrice = t(`${trPrefix}tooltip.dependentPrice`)
  const priceAfterInput = t(`${trPrefix}tooltip.priceAfterInput`)
  const addCosts = t(`${trPrefix}tooltip.addCosts`)
  const addCostsLong = t(`${trPrefix}tooltip.addCostsLong`)

  const content = [`<b>${dependentPrice}</b>`, priceAfterInput, `<b>${addCosts}</b>`, addCostsLong].join('<br/>')

  return { content, html: true, triggers: ['hover', 'click'] }
})

function toggle() {
  useToggler().toggle(toggleName)
}

function onBookingClick(isOption: boolean) {
  if (props.state === CheckPriceState.Available) {
    emit('book', { isOption })
  } else {
    toggle()
  }
}

function onAdditionalCostsTooltipMouseEnter() {
  useTracking().handlers?.detail.tooltipAdditionalCosts()
}
</script>
