const HASH_PLACEHOLDER = ':hash'

export function useEmails() {
  const query = computed(() => useRoute().query)
  const path = computed(() => useRoute().path.split('/'))
  const hash = computed(() => path.value[2] || HASH_PLACEHOLDER)
  const action = computed(() => _parseAction(path.value[3]))
  const queryAction = computed(() => _parseAction(query.value.action?.toString() ?? ''))

  const defaultParams = computed(() => ({
    ip: useState('clientIP').value as string,
    language: useL10N().language,
    salesoffice: useConfdata().salesoffice?.code,
  }))

  function _parseAction(text: string): EmailsAction | undefined {
    if (text === 'newsletter-unsubscribe') return 'unsubscribe'
    if (['confirm', 'unsubscribe', 'optin'].includes(text)) return text as EmailsAction
    return undefined
  }

  function getActionUrl(action: EmailsAction, options?: { canonical: boolean }) {
    return options?.canonical
      ? `/emails/${hash.value}/${action === 'unsubscribe' ? 'newsletter-unsubscribe' : action}`
      : `/emails/${hash.value}?action=${action}`
  }

  async function subscribe(params: { email: string; captcha: string; salutation: string; firstname: string; lastname: string }) {
    return await useWebccApi().emailsSubscribe({
      ...defaultParams.value,
      brand: 'ih', // TODO remove
      type: 'CU',
      ...params,
    })
  }

  async function unsubscribe(reason: number) {
    return await useWebccApi().emailsUnsubscribe({ ...defaultParams.value, email: hash.value, reason })
  }

  async function confirm() {
    return await useWebccApi().emailsConfirm({ ...defaultParams.value, hash: hash.value })
  }

  async function optIn() {
    return await useWebccApi().emailsOptIn({ ...defaultParams.value, hash: hash.value })
  }

  return { hash, action, queryAction, getActionUrl, subscribe, unsubscribe, confirm, optIn }
}
