<template>
  <article class="hitboxlayout relative grid min-h-[16rem] w-full gap-x-4 rounded bg-bgr shadow">
    <HitboxGallery class="gallery aspect-[4/3]" :accom="accom" :checkin="checkin" @open="openDetailPage">
      <div
        v-if="flexTrip.flexibleCalendar && checkin && checkout && accom.trip?.checkin && accom.trip?.checkout"
        class="absolute right-2 top-2 inline-flex flex-row items-center justify-center gap-[10px] rounded bg-bgr p-2 text-right opacity-80 lg:w-auto"
      >
        <span class="h-[14px] text-xs font-medium">{{ dateRageText }}</span>
      </div>
    </HitboxGallery>
    <HitboxTags class="absolute -left-2 top-2" :tags="accom.tags || []" :discount="discount" />
    <div class="features-grid">
      <header
        :class="accom.tags && accom.tags.length > 0 ? 'justify-between' : 'justify-end'"
        class="my-2 mr-2 flex h-11 items-center border-b border-bgr-300 pb-1"
      >
        <HitboxBadges class="" :tags="accom.tags || []" />
        <HitboxRating v-if="accom.reviews > 0" class="rating-grid p-1" :code="accom.code" :overall="accom.rating" :review-count="accom.reviews" />
      </header>
      <HitboxBreadcrumb v-if="accom.slug" class="" :accom="accom" :target-url="targetUrl" @open="openDetailPage" />

      <HitboxTitle class="my-1" :accom="accom" :target-url="targetUrl" />

      <!-- TODO: Momentan identisch zu views/details/Features.vue -->
      <HitboxFeatures class="my-4" :accom="accom" />
      <WebccFreeCancellationInfo
        v-if="checkin"
        v-track:impression="{ event: 'search:freeCancellationImpression' }"
        :checkin-date="checkin"
        :free-cancellation-range="confData.freeCancellationRange"
        :locale="$i18n.locale"
      />
    </div>
    <div class="actions-grid flex items-center justify-start">
      <WebccButton class="m-2" icon="favorites/map" @click="toggleMap">
        <span>{{ $t(`${trPrefix}location`) }}</span>
      </WebccButton>
      <client-only v-if="allowBookmark">
        <WebccButton :variant="isBookmarked ? 'theme' : ''" size="md" icon="site/bookmark" :icon-filled="!isBookmarked" @click="onBookmarkClick">
          <span>{{ $t(`${trPrefix}${isBookmarked ? 'bookmarkDel' : 'bookmarkAdd'}`) }}</span>
        </WebccButton>
      </client-only>
    </div>

    <!-- TODO: MaxPax aus validities?       :duration="trip.duration"
        :max-pax="accommodation.pax.capacity"
  -->

    <div class="price-grid flex items-center justify-between">
      <HitboxPrice v-if="accom.trip && accom.trip.price" class="p-0" :trip="accom.trip" :currency="confData.currency" />
      <WebccButton variant="cta" size="md" class="m-2" @click="ctaClick">
        <span>{{ $t(`${trPrefix}discover`) }}</span>
      </WebccButton>
    </div>

    <GoogleMaps
      v-if="mapVisibilityState"
      :center="{ lat: accom.coords.lat, lng: accom.coords.lon }"
      :marker="accom.name"
      class="accommodation-map rounded-b pb-[50%]"
      :accom="accom"
      :locale="confData.locale"
      :domain="confData.domain"
    />
  </article>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.'

const params = useParams()
const tracking = useTracking()
const bookmarks = useBookmarks()
const flexTrip = useFlexTrip()
const { buildAccommodationUrl } = useURLs()

const props = defineProps<{
  accom: ResultDocument
  checkin?: Date
  checkout?: Date
  pax?: number
}>()

const emit = defineEmits<{
  interaction: [value: { bookmark?: { accom: ResultDocument; state: boolean }; map?: boolean }]
}>()

const confData = useConfdata()

const mapVisibilityState = ref(false)

const allowBookmark = computed(() => useStorageReady())
const isBookmarked = computed(() => bookmarks.raw.includes(props.accom.code))
const targetUrl = computed(() => buildAccommodationUrl(props.accom))
const discount = computed(() => props.accom.trip?.reduction ?? 0)
const dateRageText = computed(() => formatDateRange(getTripDateRange(props.accom.trip ?? {}), 'monthDay'))

function onBookmarkClick() {
  if (isBookmarked.value) {
    tracking.handlers?.ecommerce.removeFromWishlist([props.accom])
  } else {
    tracking.handlers?.ecommerce.addToWishlist([props.accom])
  }
  bookmarks.toggle(props.accom.code)
  emit('interaction', { bookmark: { accom: props.accom, state: isBookmarked.value } })
}

function ctaClick() {
  openDetailPage()
}

function openDetailPage() {
  tracking.handlers?.ecommerce.selectItem(props.accom)
  tracking.handlers?.eec.productClick(props.accom)
  window.open(targetUrl.value, params.iframe ? '_self' : undefined)
}

function toggleMap() {
  mapVisibilityState.value = !mapVisibilityState.value
  emit('interaction', { map: mapVisibilityState.value })
}
</script>

<style scoped>
.hitboxlayout {
  grid-template-columns: minmax(275px, 1fr) 2fr;
  grid-template-areas:
    'image features'
    'footerActions price'
    'map map';
}

.gallery {
  grid-area: image;
}
.badges-grid {
  grid-area: badges;
}
.rating-grid {
  grid-area: rating;
}
.breadcrumb-grid {
  grid-area: breadcrumb;
}
.title-grid {
  grid-area: title;
}
.features-grid {
  grid-area: features;
}
.actions-grid {
  grid-area: footerActions;
}
.price-grid {
  grid-area: price;
}
.cta-grid {
  grid-area: footerCta;
}
.accommodation-map {
  grid-area: map;
}
</style>
