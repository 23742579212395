<template>
  <HeadlessListbox v-model="sorting">
    <div class="relative text-sm">
      <HeadlessListboxButton
        class="flex items-center justify-between rounded-full border border-bgr-300 hover:bg-bgr-100 transition focus:bg-bgr-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover h-11 p-3 w-full"
      >
        <span>{{ $t(`${trPrefix}prefix`) }} {{ $t(`${trPrefix}${OPTIONS[sorting]}`) }}</span>
        <WebccIcon name="site/arrow-down" class="h-2.5 w-2.5 ml-4 mr-2" />
      </HeadlessListboxButton>

      <transition
        :class="classes.transform"
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-100 ease-out"
        leave-to-class="transform scale-95 opacity-0"
        leave-from-class="transform scale-100 opacity-100"
      >
        <HeadlessListboxOptions
          class="absolute z-10 mt-2 py-1 rounded-lg border border-bgr-300 shadow-lg origin-top-left bg-bgr focus:outline-none"
          :class="classes.list"
        >
          <HeadlessListboxOption v-for="(label, value) in OPTIONS" v-slot="{ active, selected }" :key="value" :value="value" as="template">
            <div class="flex items-center py-1 px-4 gap-4" :class="[{ 'bg-bgr-100 outline-none cursor-pointer': active }, classes.item]">
              <WebccIcon name="site/tick" class="h-2.5 w-2.5" :class="{ invisible: !selected }" />
              <span>{{ $t(`${trPrefix}${label}`) }}</span>
            </div>
          </HeadlessListboxOption>
        </HeadlessListboxOptions>
      </transition>
    </div>
  </HeadlessListbox>
</template>

<script setup lang="ts">
type Mode = 'fit' | 'left' | 'right'

const props = withDefaults(
  defineProps<{
    /**
     * How to align the options menu:
     * - `fit` — fit the button width
     * - `left` — to the left side
     * - `right` — to the right side
     *
     * @default fit
     */
    mode?: Mode
  }>(),
  {
    mode: 'fit',
  },
)

const trPrefix = 'www.components.views.search.area.sorting.'

const OPTIONS = {
  recommendation: 'recommendation',
  '-lastbookday': 'bestsellers',
  age: 'age',
  '-averagerating': 'rating',
  price: 'priceAsc',
  '-price': 'priceDesc',
  pax: 'paxAsc',
  '-pax': 'paxDesc',
} as const

const MODE_CLASSES = {
  left: { list: 'left-0', item: 'text-nowrap', transform: 'origin-top-left' },
  right: { list: 'right-0', item: 'text-nowrap', transform: 'origin-top-right' },
  fit: { list: 'left-0 right-0', item: '', transform: 'origin-top' },
} as const

const model = defineModel<SortParams>({ required: true })

const sorting = computed({
  get: () => model.value.sorting ?? SORT_DEFAULT,
  set: (value: SortType) => (model.value = { sorting: value === SORT_DEFAULT ? undefined : value }),
})

const classes = computed(() => MODE_CLASSES[props.mode])
</script>
