<template>
  <div>
    <section class="w-full rounded shadow bg-bgr p-4 pb-16">
      <WebccHeading :level="3">{{ $t(`${trPrefix}noResults`) }}</WebccHeading>
      <span>{{ $t(`${trPrefix}modifyFilters`) }}</span>
      <FilterTags />
      <WebccButton @click="reset">{{ $t(`${trPrefix}removeAllFilters`) }}</WebccButton>
    </section>

    <section v-if="recommendation" class="mt-8">
      <h3 class="mb-6 px-4 py-3.5 border-2 border-bgr-300 rounded-lg text-xl font-semibold">
        {{ $t(`${trPrefix}noResultsRecommender.${recommendation.key}`) }}
      </h3>
      <div class="hidden md:grid gap-4 justify-start">
        <HitboxOrig v-for="item in recommendation.items" :key="`${item.code}_orig`" :accom="item" />
      </div>
      <div class="md:hidden gap-4 sm:grid sm:grid-cols-2">
        <HitboxCard v-for="item in recommendation.items" :key="`${item.code}_card`" :accom="item" />
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import pickBy from 'lodash-es/pickBy'
import zip from 'lodash-es/zip'

const trPrefix = 'www.components.views.search.area.'

type RecommendationKey = 'sameRegion' | 'extendedDates'

const DISPLAY_COUNT_MAX = 10
const FUZZINESS = 3

const confData = useConfdata()
const search = useSearch()
const searchApi = useSearchApi()

const requests = computed(() => {
  const filters = { ...search.filters, pagesize: DISPLAY_COUNT_MAX }
  const queries: Record<RecommendationKey, Params | undefined> = {
    sameRegion: filters.place ? { ...filters, place: undefined } : undefined,
    extendedDates: hasDateParams(filters) ? { ...filters, ...addDatesFuzziness(filters, FUZZINESS) } : undefined,
  }

  return pickBy(queries, Boolean) as Partial<Record<RecommendationKey, Params>>
})

const { data: recommendation } = await useAsyncData(() => searchApi.searchMulti(Object.values(requests.value), confData.language), {
  watch: [requests, () => confData.language],
  transform(results) {
    const keys = Object.keys(requests.value) as RecommendationKey[]
    const [key, result] = zip(keys, results).find(([, res]) => res?.docs.length) ?? []

    return key && result?.docs.length ? { key, items: result?.docs } : undefined
  },
})

function reset() {
  search.setFilter({}, { reset: true })
}
</script>
